










































































































































import Vue from 'vue'

export default Vue.extend({
  name: 'Home',

  data: () => ({
    about: {
      title: 'LIV[ING] [DED]ICATION',
      definition:
        '<strong>Livication</strong> is a blend of the words <em>living</em> and <em>dedication.</em>',
      sections: [
        {
          title: 'MISSION',
          paragraphs: [
            'Who ever said <em>technology</em> had to be so <em>technical?</em><br /> Livication combines the creative arts with technology and engineering<br /> to create innovative experiences for businesses of all sizes.',
            'Physically located on the Space Coast of Florida, we live among those who aim for the stars.<br /> Digitally located within the Metaverse, we embrace distant friends and diverse experiences.'
          ],
          showing: false
        }
      ]
    },
    services: {
      title: 'SERVICES',
      sections: [
        {
          title: 'BLOCKCHAIN CONSULTING &amp; DEVELOPMENT',
          imagePath: `${require('@/assets/blockchain.jpg')}`,
          paragraphs: [
            'First we <em>read</em> the internet, then we <em>wrote</em> the internet, now we <em>own</em> the internet.',
            'Let Livication bring your business into the new Internet age.<br /> We understand the potential that Web3 technologies have to put power into the hands of individuals.<br /> Find out how your business can benefit from blockchain, smart contracts, non-fungible tokens, and decentralized currencies.'
          ]
        },
        {
          title: 'CRYPTOCURRENCY MINING',
          imagePath: `${require('@/assets/mining.jpg')}`,
          paragraphs: [
            'Make passive income supporting networks that support a more decentralized world.',
            'Livication can help get you started with cryptocurrency mining by sourcing equipment, planning or building your mining farm, reducing your electric bill with renewable energy sources, and optimizing your build for maximum efficiency, safety, and maintainability.'
          ]
        },
        {
          title: 'METAVERSE DEVELOPMENT',
          imagePath: `${require('@/assets/metaverse.jpg')}`,
          paragraphs: [
            'Bring your business into a world of 24/7 worldwide advertisement!',
            `Rent or buy land in the Metaverse and garner worldwide recognition for your brand for 24 hours a day.<br /> Benefit from a world that is less saturated with advertisements, and find an audience that is eager for new experiences.<br /> Build a home for your business that is unbound by the limitations of the physical world.<br /> Today a floating island, tomorrow a skyscraper, the next day a festival stage.<br /> Keep your audience wondering what you'll do next!`
          ]
        },
        {
          title: 'SOFTWARE PRODUCT PLANNING &amp; DEVELOPMENT',
          imagePath: `${require('@/assets/software.jpg')}`,
          paragraphs: [
            'Build that thing that nobody has thought of yet!',
            'Plan the architecture of your software project, determine a scope and budget, <br />and hire qualified software engineers that can bring your vision to life.<br /> Some projects can be built in-house by Livication!'
          ]
        },
        {
          title: 'LIVE VIDEO PRODUCTION',
          imagePath: `${require('@/assets/video.jpg')}`,
          paragraphs: [
            'Put away the shaky cell phone feed and take your live stream to the next level!',
            'We can stream your event, performance, or seminar with professional-quality broadcasting equipment.<br /> Switch between multiple cameras, add virtual backgrounds, wire in professionally mixed audio, and more.<br /> Show your innovative side and stream your live event from our land in the Metaverse!'
          ]
        },
        {
          title: 'AUDIO ENGINEERING',
          imagePath: `${require('@/assets/audio-board.jpg')}`,
          paragraphs: [
            'Make your business heard!',
            `Provide a compelling aural experience for your product with Livication's audio engineering services.<br /> Our in-house studio can provide sound design, music production, voice-over recording, podcast production, and more.<br /> Have a live event coming up that needs to sound amazing? Livication can provide on-site live sound engineering.`
          ]
        },
        {
          title: 'CUSTOM AUTOMATION',
          imagePath: `${require('@/assets/automation.jpg')}`,
          paragraphs: [
            'Leave the menial, tedious tasks to the robots. Save your time and sanity with automation!',
            `Let your aquarium change its own water.<br /> Have your doors lock themselves when you go to bed.<br /> Never worry again about whether you forgot to close the garage.<br /> We can recommend automation solutions for your home or business<br /> and help you program them to compliment your busy schedule.`
          ]
        }
      ]
    }
  })
})
