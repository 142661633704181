var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-parallax',{attrs:{"dark":"","src":require("@/assets/space-paint.jpg"),"height":"300"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-img',{attrs:{"src":require('@/assets/Livication_Logo_Light.svg'),"contain":"","max-height":"100"}})],1)],1)],1)],_c('v-sheet',{staticClass:"mx-auto",attrs:{"elevation":"2","max-width":"960"}},[_c('v-container',[_c('v-row',{staticClass:"text-center mt-4"},[_c('v-col',{staticClass:"mb-4"},[_c('h1',{staticClass:"display-2 font-weight-bold mb-4"},[_vm._v(" "+_vm._s(_vm.about.title)+" ")]),_c('v-divider',{staticClass:"mx-auto mb-4",attrs:{"width":"200"}}),_c('p',{staticClass:"title font-weight-regular text-center mb-0"}),_c('p',{staticClass:"text-body-2 font-weight-regular text-center mt-0",domProps:{"innerHTML":_vm._s(_vm.about.definition)}}),_c('v-divider',{staticClass:"mx-auto mb-4",attrs:{"width":"200"}}),_vm._l((_vm.about.sections),function(section,i){return _c('div',{key:i},[_c('v-lazy',{key:i,staticClass:"text-center mx-auto",attrs:{"options":{
                threshold: 0.5
              },"transition":"fade-transition"},model:{value:(section.showing),callback:function ($$v) {_vm.$set(section, "showing", $$v)},expression:"section.showing"}},[_c('h1',{staticClass:"headline font-weight-bold mb-4"},[_vm._v(" "+_vm._s(section.title)+" ")])]),_vm._l((section.paragraphs),function(paragraph,x){return _c('div',{key:x},[_c('v-lazy',{key:i,staticClass:"text-center mx-auto",attrs:{"options":{
                  threshold: 0.5
                },"transition":"fade-transition"},model:{value:(section.showing),callback:function ($$v) {_vm.$set(section, "showing", $$v)},expression:"section.showing"}},[_c('p',{staticClass:"subheading font-weight-regular text-center",domProps:{"innerHTML":_vm._s(paragraph)}})])],1)})],2)})],2),_c('v-col',{staticClass:" text-center",attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-2 font-weight-bold mb-4",domProps:{"innerHTML":_vm._s(_vm.services.title)}}),_vm._l((_vm.services.sections),function(section,i){return _c('div',{key:i},[_c('v-lazy',{staticClass:"text-center mx-auto",attrs:{"options":{
                threshold: 0.5
              },"transition":"fade-transition"}},[_c('section',[_c('h2',{staticClass:"headline font-weight-bold mb-4",domProps:{"innerHTML":_vm._s(section.title)}}),_c('v-divider',{staticClass:"mx-auto mb-4",attrs:{"width":"200"}})],1)]),_c('v-lazy',{staticClass:"text-center mx-auto",attrs:{"options":{
                threshold: 0.5
              },"transition":"fade-transition"}},[_c('section',[_c('v-img',{staticClass:"mx-auto my-3",attrs:{"src":section.imagePath,"height":"150","width":"60%"}}),_vm._l((section.paragraphs),function(paragraph,p){return _c('div',{key:p,staticClass:"paragraph mx-auto"},[_c('p',{staticClass:"subheading font-weight-regular text-center d-inline-block",domProps:{"innerHTML":_vm._s(paragraph)}})])}),(i < _vm.services.sections.length - 1)?_c('v-divider',{staticClass:"mx-auto mb-4",attrs:{"width":"200"}}):_vm._e()],2)])],1)})],2),_c('v-col',{staticClass:"mb-6",attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-2 font-weight-bold mb-3"},[_vm._v("GET STARTED")]),_c('v-divider',{staticClass:"mx-auto mb-3",attrs:{"width":"200"}}),_c('h2',{staticClass:"headline font-weight-bold mb-3"},[_vm._v(" CONTACT US TODAY ")]),_c('p',{staticClass:"subheading font-weight-regular text-center"},[_c('strong',[_vm._v("Email:")]),_vm._v(" info@livication.com"),_c('br'),_c('strong',[_vm._v("Phone/Text Message:")]),_vm._v(" 303-775-7933 (Supports iMessage)"),_c('br'),_c('a',{attrs:{"href":"https://discord.gg/sjtNgQvJAU"}},[_c('strong',[_vm._v("Click To Join Our Discord Server")])])])],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }